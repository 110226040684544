export default function CohereAiOptions({ settings, moduleSuffix = "" }) {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            Cohere API Key
          </label>
          <input type="password" name={`CohereApiKey${moduleSuffix}`} placeholder="Cohere API Key" defaultValue={settings?.[`CohereApiKey${moduleSuffix}`] ? "*".repeat(20) : ""} required={true} autoComplete="off" spellCheck={false}
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5"
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            Chat Model Selection
          </label>
          <select
            name={`CohereModelPref${moduleSuffix}`}
            defaultValue={settings?.[`CohereModelPref${moduleSuffix}`] || "command-r"}
            required={true}
            className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-2.5"
          >
            {[
              "command-r",
              "command-r-plus",
              "command",
              "command-light",
              "command-nightly",
              "command-light-nightly",
            ].map((model) => {
              return (
                <option key={model} value={model} className="normal-text">
                  {model}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}
