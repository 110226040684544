const TRANSLATIONS = {
  common: {
    "workspaces-name": "Navn på arbeidsområde",
    error: "feil",
    success: "suksess",
    user: "Bruker",
    selection: "Modellvalg",
    saving: "Lagrer...",
    save: "Lagre endringer",
    previous: "Forrige side",
    next: "Neste side",
  },

  // Setting Sidebar menu items.
  settings: {
    title: "Instansinnstillinger",
    system: "Generelle innstillinger",
    invites: "Invitasjoner",
    users: "Brukere",
    workspaces: "Arbeidsområder",
    "workspace-chats": "Arbeidsområdets chatter",
    customization: "Tilpasning",
    "api-keys": "Utvikler-API",
    llm: "LLM",
    transcription: "Transkripsjon",
    embedder: "Inbedding",
    "text-splitting": "Tekstdeling & Chunking",
    "vector-database": "Vektordatabasen",
    embeds: "Chat Inbedding",
    "embed-chats": "Inbeddingschatthistorikk",
    security: "Sikkerhet",
    "event-logs": "Hendelseslogger",
    privacy: "Personvern & Data",
    "ai-providers": "AI-leverandører",
    "agent-skills": "Agentferdigheter",
    admin: "Administrator",
    tools: "Verktøy",
    audio: "Lydinnstillinger",
    "link-settings": "Innstillinger",
  },

  // Page Definitions
  login: {
    "multi-user": {
      welcome: "Velkommen til",
      "placeholder-username": "Brukernavn",
      "placeholder-password": "Passord",
      login: "Logg inn",
      validating: "Validerer...",
      "forgot-pass": "Glemt passord",
      reset: "Tilbakestill",
    },
    "sign-in": {
      start: "Logg inn på kontoen din",
      end: "konto.",
    },
    button: "logg inn",
    password: {
      forgot: "GLEMT PASSORD?",
      contact: "Kontakt systemadministratoren.",
    },
    publicMode: "Offentlig modus",
  },

  "new-workspace": {
    title: "Nytt arbeidsområde",
    placeholder: "Mitt arbeidsområde",
    "legal-areas": "Rettområder",
    create: {
      title: "Opprett nytt arbeidsområde",
      description:
        "Etter å ha opprettet dette arbeidsområdet vil kun administratorer kunne se det. Du kan legge til brukere etter at det er opprettet.",
      error: "Feil: ",
      cancel: "Avbryt",
      "create-workspace": "Opprett arbeidsområde",
    },
  },

  "workspace-chats": {
    welcome: "Velkommen til ditt nye arbeidsområde.",
    "desc-start": "For å komme i gang, enten",
    "desc-mid": "last opp et dokument",
    "desc-or": "eller",
    start: "For å komme i gang",
    "desc-end": "send en melding.",
    prompt: {
      send: "Send",
      "send-message": "Send melding",
      placeholder: "Be om juridisk informasjon",
      slash: "Vis alle tilgjengelige kommandoer for å chatte.",
      "change-size": "Endre tekststørrelse",
      reset: "/tilbakestill",
      clear: "Tøm chatthistorikken din og start en ny chat",
      "new-preset": "Legg til ny forhåndsinnstilling",
      command: "Kommando",
      description: "Beskrivelse",
      save: "lagre",
      small: "Liten",
      normal: "Normal",
      large: "Stor",
      attach: "Legg ved en fil til denne chatten",
    },
  },

  header: {
    account: "Konto",
    login: "Logg inn",
    "sign-out": "Logg ut",
  },

  workspace: {
    title: "Instansarbeidsområder",
    description:
      "Dette er alle arbeidsområder som finnes på denne instansen. Å slette et arbeidsområde sletter alle tilknyttede chatter og innstillinger.",
    "new-workspace": "Nytt Arbeidsområde",
    name: "Navn",
    link: "Lenke",
    users: "Brukere",
    "created-on": "Opprettet Den",
    save: "Lagre endringer",
    cancel: "Avbryt",
    deleted: {
      title: "Arbeidsområde ikke funnet!",
      description:
        "Det ser ut som et arbeidsområde med dette navnet ikke er tilgjengelig.",
      homepage: "Gå tilbake til startsiden",
    },
  },

  // Workspace Settings menu items
  "workspaces-settings": {
    general: "Generelle Innstillinger",
    chat: "Chatinnstillinger",
    vector: "Vektordatabasen",
    members: "Medlemmer",
    agent: "Agentkonfigurasjon",
    "general-settings": {
      "workspace-name": "Arbeidsområdets Navn",
      "desc-name":
        "Dette vil bare endre visningsnavnet på arbeidsområdet ditt.",
      "assistant-profile": "Assistentens Profilbilde",
      "assistant-image":
        "Tilpass profilbildet for assistenten for dette arbeidsområdet.",
      "workspace-image": "Arbeidsområdets Bilde",
      "remove-image": "Fjern Arbeidsområdets Bilde",
      delete: "Slett Arbeidsområde",
      deleting: "Sletter Arbeidsområde...",
      update: "Oppdater arbeidsområde",
      updating: "Oppdaterer arbeidsområde...",
    },
    "chat-settings": {
      type: "Chat Type",
      private: "Privat",
      standard: "Standard",
      "private-desc-start": "vil manuelt gi tilgang til",
      "private-desc-mid": "kun",
      "private-desc-end": "spesifikke brukere.",
      "standard-desc-start": "vil automatisk gi tilgang til",
      "standard-desc-mid": "alle",
      "standard-desc-end": "nye brukere.",
    },
    users: {
      manage: "Administrer Brukere",
      "workspace-member": "Ingen arbeidsområdemedlemmer",
      username: "Brukernavn",
      role: "Rolle",
      date: "Dato lagt til",
      users: "Brukere",
      search: "Søk etter en bruker",
      "no-user": "Ingen brukere funnet",
      select: "Velg Alle",
      unselect: "Fjern Alle",
      save: "Lagre",
    },
    "linked-workspaces": {
      title: "Koblede Arbeidsområder",
      "linked-workspace": "Ingen koblede arbeidsområder",
      manage: "Administrer Arbeidsområder",
      name: "Navn",
      slug: "Slug",
      date: "Dato Lagt Til",
      workspaces: "Arbeidsområder",
      search: "Søk etter et arbeidsområde",
      "no-workspace": "Ingen arbeidsområder funnet",
      select: "Velg Alle",
      unselect: "Opphev Valg",
      save: "Lagre",
    },
  },

  // General Appearance
  general: {
    vector: {
      title: "Antall vektorer",
      description: "Totalt antall vektorer i din vektordatabasen.",
      vectors: "Antall vektorer",
    },
    names: {
      description:
        "Dette vil bare endre visningsnavnet på arbeidsområdet ditt.",
    },
    message: {
      title: "Foreslåtte meldinger",
      description:
        "Tilpass meldingene som vil bli foreslått for brukerne av arbeidsområdet ditt.",
      add: "Legg til ny melding",
      save: "Lagre meldinger",
      heading: "Forklar for meg",
      body: "fordelene med plattformen",
      message: "Melding",
      "new-heading": "Overskrift",
    },
    pfp: {
      title: "Profilbilde for assistent",
      description:
        "Tilpass profilbildet for assistenten for dette arbeidsområdet.",
      image: "Arbeidsområdets bilde",
      remove: "Fjern arbeidsområdets bilde",
    },
    delete: {
      delete: "Slett arbeidsområde",
      deleting: "Sletter arbeidsområde...",
      "confirm-start": "Du holder på å slette hele din",
      "confirm-end":
        "arbeidsområde. Dette vil fjerne alle vektorinbeddingene i din vektordatabasen. \n \n Originalkildene vil forbli urørt. Denne handlingen er irreversibel.",
    },
  },

  // Chat Settings
  chat: {
    llm: {
      title: "Arbeidsområdets LLM-leverandør",
      description:
        "Den spesifikke LLM-leverandøren & modellen som vil bli brukt for dette arbeidsområdet. Som standard bruker den systemets LLM-leverandør og innstillinger.",
      search: "Søk alle LLM-leverandører",
    },
    model: {
      title: "Arbeidsområdets chatmodell",
      description:
        "Den spesifikke chatmodellen som vil bli brukt for dette arbeidsområdet. Hvis tomt, vil systemets LLM-preferanse bli brukt.",
      wait: "-- venter på modeller --",
    },
    mode: {
      title: "Chatmodus",
      chat: {
        title: "Chat",
        "desc-start": "vil gi svar med LLMs generelle kunnskap",
        and: "og",
        "desc-end": "dokumentkontext som finnes.",
      },
      query: {
        title: "Spørsmål",
        "desc-start": "vil gi svar",
        only: "kun",
        "desc-end": "hvis dokumentkontext finnes.",
      },
    },
    history: {
      title: "Chatthistorikk",
      "desc-start":
        "Antall tidligere chatter som vil bli inkludert i svarets korttidsminne.",
      recommend: "Anbefaler 20. ",
      "desc-end":
        "Alt mer enn 45 er sannsynlig å føre til kontinuerlige chatfeil avhengig av meldingsstørrelse.",
    },
    prompt: {
      title: "Prompt",
      description:
        "Prompten som vil bli brukt på dette arbeidsområdet. Definer kontekst og instruksjoner for AI-en for å generere et svar. Du bør gi en nøye utformet prompt slik at AI-en kan generere et relevant og korrekt svar.",
    },
    refusal: {
      title: "Avvisende svar i spørremodus",
      "desc-start": "Når i",
      query: "spørsmål",
      "desc-end":
        "modus, kan det være lurt å gi et tilpasset avvisende svar når ingen kontext finnes.",
    },
    temperature: {
      title: "LLMs temperatur",
      "desc-start":
        'Denne innstillingen styrer hvor "kreative" LLM-svarene dine vil være.',
      "desc-end":
        "Jo høyere nummer, desto mer kreativt. For noen modeller kan dette føre til usammenhengende svar når det er stilt for høyt.",
      hint: "De fleste LLM-er har forskjellige akseptable verdier. Konsulter din LLM-leverandør for den informasjonen.",
    },
  },

  // Vector Database
  "vector-workspace": {
    identifier: "Identifikator for vektordatabasen",
    snippets: {
      title: "Maksimalt antall kontekstsnippets",
      description:
        "Denne innstillingen styrer det maksimale antallet kontekstsnippets som vil bli sendt til LLM per chat eller spørsmål.",
      recommend: "Anbefalt: 4",
    },
    doc: {
      title: "Terskelverdi for dokumentsimilaritet",
      description:
        "Den minste likhetspoengsummen som kreves for at en kilde skal anses som relatert til chatten. Jo høyere poeng, desto mer lik må kilden være chatten.",
      zero: "Ingen begrensning",
      low: "Lav (likhetspoeng ≥ .25)",
      medium: "Moderat (likhetspoeng ≥ .50)",
      high: "Høy (likhetspoeng ≥ .75)",
    },
    reset: {
      reset: "Tilbakestill vektordatabasen",
      resetting: "Tømmer vektorer...",
      confirm:
        "Du holder på å tilbakestille vektordatabasen for dette arbeidsområdet. Dette vil fjerne alle vektorinbeddingene som for øyeblikket er inbeddet. \n \n Originalkildene vil forbli urørt. Denne handlingen er irreversibel.",
      error: "Vektordatabasen for arbeidsområdet kunne ikke tilbakestilles!",
      success: "Vektordatabasen for arbeidsområdet ble tilbakestilt!",
    },
  },

  // Agent Configuration
  agent: {
    "performance-warning":
      "Ytelsen til LLM-er som ikke uttrykkelig støtter verktøykall er sterkt avhengig av modellens kapasitet og nøyaktighet. Noen evner kan være begrenset eller ikke-funksjonelle.",
    provider: {
      title: "Arbeidsområdets LLM-leverandør",
      description:
        "Den spesifikke LLM-leverandøren & modellen som vil bli brukt for agenten @agent for dette arbeidsområdet.",
    },
    mode: {
      chat: {
        title: "Arbeidsområdets agentchatmodell",
        description:
          "Den spesifikke chatmodellen som vil bli brukt for agenten @agent for dette arbeidsområdet.",
      },
      title: "Arbeidsområdets agentmodell",
      description:
        "Den spesifikke LLM-modellen som vil bli brukt for agenten @agent for dette arbeidsområdet.",
      wait: "-- venter på modeller --",
    },

    skill: {
      title: "Standardagentens ferdigheter",
      description:
        "Forbedre de naturlige evnene til standardagenten med disse forhåndsbygde ferdighetene. Denne innstillingen gjelder for alle arbeidsområder.",
      rag: {
        title: "RAG & langtidsminne",
        description:
          'La agenten utnytte dine lokale dokumenter for å svare på et spørsmål eller be agenten "huske" deler av innholdet for langtidsminnehenting.',
      },
      configure: {
        title: "Konfigurer Agentens Ferdigheter",
        description:
          "Tilpass og forbedre agentens standardkapasiteter ved å aktivere eller deaktivere spesifikke ferdigheter. Disse innstillingene vil bli brukt på alle arbeidsområder.",
      },
      view: {
        title: "Vis & oppsummer dokumenter",
        description:
          "La agenten liste og oppsummere innholdet i arbeidsområdets filer som for øyeblikket er inbeddet.",
      },
      scrape: {
        title: "Hent innhold fra nettsteder",
        description: "La agenten besøke og hente innhold fra nettsteder.",
      },
      generate: {
        title: "Generer diagrammer",
        description:
          "Aktiver standardagenten for å generere forskjellige typer diagrammer fra data som gis eller gis i chatten.",
      },
      save: {
        title: "Generer & lagre filer til nettleseren",
        description:
          "Aktiver standardagenten for å generere og skrive til filer som kan lagres og lastes ned i nettleseren din.",
      },
      web: {
        title: "Live nettsøk og surfing",
        "desc-start":
          "Aktiver agenten din for å søke på nettet for å svare på spørsmålene dine ved å koble til en web-søk (SERP) leverandør.",
        "desc-end":
          "Nettlesing under agentsesjoner fungerer ikke før dette er satt opp.",
      },
    },
  },

  // Workspace Chats
  recorded: {
    title: "Arbeidsområdets chatter",
    description:
      "Dette er alle innspilte chatter og meldinger som er sendt av brukere ordnet etter deres opprettelsesdato.",
    export: "Eksporter",
    table: {
      id: "Id",
      by: "Sendt av",
      workspace: "Arbeidsområde",
      prompt: "Prompt",
      response: "Svar",
      at: "Sendt ved",
    },
    "clear-chats": "Tøm Chatter",
  },

  // Appearance
  appearance: {
    title: "Utseende",
    description: "Tilpass utseendeinnstillingene på plattformen din.",
    logo: {
      title: "Tilpass logo",
      description:
        "Last opp din tilpassede logo for å gjøre chatboten din til din egen.",
      add: "Legg til en tilpasset logo",
      recommended: "Anbefalt størrelse: 800 x 200",
      remove: "Fjern",
      replace: "Bytt ut",
    },
    message: {
      title: "Tilpass meldinger",
      description:
        "Tilpass de automatiske meldingene som vises for brukerne dine.",
      new: "Ny",
      system: "system",
      user: "bruker",
      message: "melding",
      assistant: "chatassistent",
      "double-click": "Dobbeltklikk for å redigere...",
      save: "Lagre meldinger",
    },
    icons: {
      title: "Tilpassede bunntekstikoner",
      description: "Tilpass bunntekstikonene som vises nederst i sidemenyen.",
      icon: "Ikon",
      link: "Lenke",
    },
    display: {
      title: "Visningsspråk",
      description:
        "Velg det foretrukne språket for å gjengi brukergrensesnittet, når det er aktuelt.",
    },
    color: {
      title: "Tilpassede farger",
      "desc-start": "Tilpass",
      "desc-mid": "*bakgrunnsfarge, *primærfarge",
      "desc-and": "og",
      "desc-end": "*tekstfarge",
    },
    login: {
      title: "Tilpasset innloggningstekst",
      description: "Tilpass teksten som vises på innloggingssiden.",
      save: "lagre",
    },
  },

  // API Keys
  api: {
    title: "API-nøkler",
    description:
      "API-nøkler tillater innehaveren å programmatisk få tilgang til og administrere denne plattformen.",
    link: "Les API-dokumentasjonen",
    generate: "Generer ny API-nøkkel",
    table: {
      key: "API-nøkkel",
      by: "Opprettet av",
      created: "Opprettet",
    },
    new: {
      title: "Opprett ny API-nøkkel",
      description:
        "Når den er opprettet, kan API-nøkkelen brukes til å programmatisk få tilgang til og konfigurere denne plattformen.",
      doc: "Les API-dokumentasjonen",
      cancel: "Avbryt",
      "create-api": "Opprett API-nøkkel",
    },
  },

  llm: {
    title: "LLM-preferanse",
    description:
      "Dette er dine referanser og innstillinger for din foretrukne LLM-chat- og inbeddingstjeneste. Det er viktig at disse nøklene er oppdaterte og korrekte, ellers vil ikke systemet fungere korrekt.",
    provider: "LLM-leverandør",
    "none-selected": "Ingen valgt",
    "select-llm": "Du må velge en LLM",
    "search-llm": "Søk alle LLM-leverandører",
  },

  "llm-provider": {
    openai:
      "Det standardalternativet for de fleste ikke-kommersielle bruksområder.",
    azure: "Bedriftsalternativet for OpenAI som hostes på Azure-tjenester.",
    anthropic: "En vennlig AI-assistent hostet av Anthropic.",
    gemini: "Googles største og mest kapable AI-modell",
    huggingface:
      "Tilgang til 150 000+ open-source LLM-er og verdens AI-fellesskap",
    ollama: "Kjør LLM-er lokalt på din egen maskin.",
    lmstudio:
      "Oppdag, last ned og kjør tusenvis av banebrytende LLM-er med noen få klikk.",
    localai: "Kjør LLM-er lokalt på din egen maskin.",
    togetherai: "Kjør open source-modeller fra Together AI.",
    mistral: "Kjør open source-modeller fra Mistral AI.",
    perplexityai:
      "Kjør kraftfulle og internettilkoblede modeller hostet av Perplexity AI.",
    openrouter: "Et enhetlig grensesnitt for LLM-er.",
    groq: "Den raskeste LLM-inferensen som finnes for sanntids-AI-applikasjoner.",
    koboldcpp: "Kjør lokale LLM-er med koboldcpp.",
    oobabooga: "Kjør lokale LLM-er med Oobaboogas Text Generation Web UI.",
    cohere: "Kjør Cohere:s kraftfulle Command-modeller.",
    lite: "Kjør LiteLLM:s OpenAI-kompatible proxy for forskjellige LLM-er.",
    "generic-openai":
      "Koble til hvilken som helst OpenAi-kompatibel tjeneste via en tilpasset konfigurasjon",
    native:
      "Bruk en nedlastet tilpasset Llama-modell for å chatte på denne plattformen.",
  },

  // audio preference
  audio: {
    title: "Innstillinger for Tale-til-tekst",
    provider: "Leverandør",
    "desc-speech":
      "Her kan du spesifisere hvilken type tale-til-tekst- og tekst-til-tale-leverandører du vil bruke. Som standard bruker vi nettleserens innebygde støtte for disse tjenestene, men du kan velge å bruke andre.",
    "title-text": "Innstillinger for Tekst-til-tale",
    "desc-text":
      "Her kan du spesifisere hvilken type tekst-til-tale-leverandører du vil bruke. Som standard bruker vi nettleserens innebygde støtte for disse tjenestene, men du kan velge å bruke andre.",
    "desc-config": "Ingen konfigurasjon nødvendig for denne leverandøren.",
    "placeholder-stt": "Søk tale-til-tekst-leverandører",
    "placeholder-tts": "Søk tekst-til-tale-leverandører",
    "native-stt": "Bruker nettleserens innebygde STT-tjeneste hvis støttet.",
    "native-tts": "Bruker nettleserens innebygde TTS-tjeneste hvis støttet.",
    openai: "Bruk OpenAIs tekst-til-tale-stemmer.",
    elevenlabs: "Bruk ElevenLabs tekst-til-tale-stemmer og teknologi.",
  },

  transcription: {
    title: "Transkripsjonsmodellpreferanse",
    description:
      "Dette er dine referanser og innstillinger for din foretrukne transkripsjonsmodellleverandør. Det er viktig at disse nøklene er oppdaterte og korrekte, ellers vil ikke mediefiler og lyd bli transkribert.",
    provider: "Transkripsjonsleverandør",
    "warn-start":
      "Bruk av den lokale whisper-modellen på maskiner med begrenset RAM eller CPU kan stoppe plattformen ved behandling av mediefiler.",
    "warn-recommend": "Vi anbefaler minst 2 GB RAM og opplastede filer <10Mb.",
    "warn-end":
      "Den innebygde modellen vil bli lastet ned automatisk ved første bruk.",
    "search-audio": "Søk etter lydtranskripsjonsleverandører",
  },

  embedding: {
    title: "Inbædningspreferanse",
    "desc-start":
      "Når du bruker en LLM som ikke nativt støtter en inbædningsmotor - kan du måtte angi ytterligere referanser for å inbede tekst.",
    "desc-end":
      "Inbedding er prosessen med å omdanne tekst til vektorer. Disse referansene kreves for å omdanne filene og promptene dine til et format som plattformen kan bruke til å behandle.",
    provider: {
      title: "Inbædningsleverandør",
      description:
        "Ingen konfigurasjon nødvendig når du bruker plattformens innebygde inbædningsmotor.",
      "search-embed": "Søk alle inbædningsleverandører",
    },
  },

  text: {
    title: "Tekstdeling & Chunking preferanser",
    "desc-start":
      "Noen ganger vil du kanskje endre standardmetoden for hvordan nye dokumenter deles og deles før de settes inn i din vektordatabasen.",
    "desc-end":
      "Du bør bare endre denne innstillingen hvis du forstår hvordan tekstdeling fungerer og dens bieffekter.",
    "warn-start": "Endringer her gjelder kun",
    "warn-center": "nyinbeddede dokumenter",
    "warn-end": ", ikke eksisterende dokumenter.",
    size: {
      title: "Tekstchunkstørrelse",
      description:
        "Dette er den maksimale lengden av tegn som kan være i en enkelt vektor.",
      recommend: "Inbædningsmodellens maksimale lengde er",
    },

    overlap: {
      title: "Tekstchunk overlapping",
      description:
        "Dette er den maksimale overlappingen av tegn som skjer under chunking mellom to tilstøtende tekstchunks.",
    },
  },

  // Vector Database
  vector: {
    title: "Vektordatabasen",
    description:
      "Dette er dine referanser og innstillinger for hvordan plattformen din vil fungere. Det er viktig at disse nøklene er oppdaterte og korrekte.",
    provider: {
      title: "Vektordatabasleverandør",
      description: "Ingen konfigurasjon nødvendig for LanceDB.",
      "search-db": "Søk alle vektordatabasleverandører",
    },
  },

  // Embeddable Chat Widgets
  embeddable: {
    title: "Inbeddingsbare chattwidgets",
    description:
      "Inbeddingsbare chattwidgets er offentlige chatgrensesnitt som er knyttet til et enkelt arbeidsområde. Disse lar deg bygge arbeidsområder som du deretter kan publisere for verden.",
    create: "Opprett inbedding",
    table: {
      workspace: "Arbeidsområde",
      chats: "Sendte chatter",
      Active: "Aktive domener",
    },
  },

  "embed-chats": {
    title: "Inbeddingschatter",
    description:
      "Dette er alle innspilte chatter og meldinger fra alle inbeddinger som du har publisert.",
    table: {
      embed: "Inbedding",
      sender: "Avsender",
      message: "Melding",
      response: "Svar",
      at: "Sendt ved",
    },
  },

  multi: {
    title: "Multi-brukermodus",
    description:
      "Sett opp instansen din for å støtte teamet ditt ved å aktivere Multi-brukermodus.",
    enable: {
      "is-enable": "Multi-brukermodus er aktivert",
      enable: "Aktiver Multi-brukermodus",
      description:
        "Som standard vil du være den eneste administratoren. Som administrator må du opprette kontoer for alle nye brukere eller administratorer. Ikke mist passordet ditt, da kun en administratorbruker kan tilbakestille passordet.",
      username: "Administratorens brukernavn",
      password: "Administratorens passord",
    },
    password: {
      title: "Passordbeskyttelse",
      description:
        "Beskytt plattformen din med et passord. Hvis du glemmer dette, er det ingen tilbakestillingsmetode, så sørg for å lagre dette passordet.",
    },
    instance: {
      title: "Passordbeskytt instans",
      description:
        "Som standard vil du være den eneste administratoren. Som administrator må du opprette kontoer for alle nye brukere eller administratorer. Ikke mist passordet ditt, da kun en administratorbruker kan tilbakestille passordet.",
      password: "Instanspassord",
    },
  },

  // Event Logs
  event: {
    title: "Hendelseslogger",
    description:
      "Vis alle handlinger og hendelser som skjer på denne instansen for overvåking.",
    clear: "Tøm hendelseslogger",
    table: {
      type: "Hendelsestype",
      user: "Bruker",
      occurred: "Intraff ved",
    },
  },

  // Privacy & Data-Handling
  privacy: {
    title: "Personvern & Datahåndtering",
    description:
      "Dette er din konfigurasjon for hvordan tilkoblede tredjepartsleverandører og plattformen håndterer dataene dine.",
    llm: "LLM-valg",
    embedding: "Inbædningspreferanse",
    vector: "Vektordatabasen",
    anonymous: "Anonym telemetri aktivert",
    "desc-event": "Alle hendelser registrerer ikke IP-adresse og inneholder",
    "desc-id": "ingen identifikasjon",
    "desc-cont":
      "innhold, innstillinger, chatter eller annen ikke-bruksbasert informasjon. For å se listen over innsamlede hendelsestagger kan du se på",
    "desc-git": "Github her",
    "desc-end":
      "Som et open-source-prosjekt respekterer vi din rett til personvern. Vi er dedikert til å bygge den beste løsningen for å integrere AI og dokumenter privat og sikkert. Hvis du bestemmer deg for å deaktivere telemetri, ber vi deg bare om å vurdere å sende oss tilbakemeldinger og tanker slik at vi kan fortsette å forbedre plattformen",
  },
  "default-chat": {
    welcome: "Velkommen til IST Legal.",
    "choose-legal": "Velg et juridisk område til venstre.",
  },
  invites: {
    title: "Invitasjoner",
    description:
      "Opprett invitasjonslenker for personer i organisasjonen din for å akseptere og registrere seg med. Invitasjoner kan kun brukes av en enkelt bruker.",
    link: "Opprett invitasjonslenke",
    status: "Status",
    accept: "Akseptert av",
    "created-by": "Opprettet av",
    created: "Opprettet",
    new: {
      title: "Opprett ny invitasjon",
      "desc-start":
        "Etter opprettelsen kan du kopiere invitasjonen og sende den til en ny bruker der de kan opprette en konto som",
      "desc-mid": "standard",
      "desc-end": "rolle og automatisk legges til valgte arbeidsområder.",
      "auto-add": "Legg til inviterte automatisk til arbeidsområder",
      "desc-add":
        "Du kan valgfritt tildele brukeren til arbeidsområdene nedenfor ved å velge dem. Som standard vil ikke brukeren ha noen synlige arbeidsområder. Du kan tildele arbeidsområder senere etter at invitasjonen er akseptert.",
      cancel: "Avbryt",
      "create-invite": "Opprett invitasjon",
      error: "Feil: ",
    },
  },

  "user-menu": {
    edit: "Rediger konto",
    profile: "Profilbilde",
    size: "800 x 800",
    "remove-profile": "Fjern profilbilde",
    username: "Brukernavn",
    "username-placeholder": "Brukerens brukernavn",
    "new-password": "Nytt passord",
    "new-password-placeholder": "nytt passord",
    cancel: "Avbryt",
    update: "Oppdater konto",
    language: "Foretrukket språk",
  },

  sidebar: {
    thread: {
      "load-thread": "laster tråder....",
      "starting-thread": "Starter tråd...",
      thread: "Ny tråd",
      delete: "Fjern valgte",
      rename: "Gi nytt navn",
      "delete-thread": "Fjern tråd",
      deleted: "fjernet",
      default: "Standard",
      "empty-thread": "Tråd",
      "rename-message": "Hva vil du gi denne tråden nytt navn?",
      "delete-message":
        "Er du sikker på at du vil slette denne tråden? Alle dens chatter vil bli slettet. Du kan ikke angre dette.",
    },
  },

  embeder: {
    allm: "Bruk den innebygde inbædningsleverandøren for plattformen. Ingen innstilling!",
    openai:
      "Det standardalternativet for de fleste ikke-kommersielle bruksområder.",
    azure: "Bedriftsalternativet for OpenAI som hostes på Azure-tjenester.",
    localai: "Kjør inbædningsmodeller lokalt på din egen maskin.",
    ollama: "Kjør inbædningsmodeller lokalt på din egen maskin.",
    lmstudio:
      "Oppdag, last ned og kjør tusenvis av banebrytende LLM-er med noen få klikk.",
    cohere: "Kjør kraftfulle inbædningsmodeller fra Cohere.",
    voyageai: "Kjør kraftfulle inbædningsmodeller fra Voyage AI.",
  },

  vectordb: {
    lancedb:
      "100 % lokal vektordatabasen som kjører på samme server som plattformen",
    chroma:
      "Åpen kildekode vektordatabasen som du kan være vert for selv eller på skyen.",
    pinecone: "100 % skybasert vektordatabasen for bedriftsbruk.",
    zilliz:
      "Skyvert vektordatabasen bygget for bedrifter med SOC 2-etterlevelse.",
    qdrant: "Åpen kildekode lokal og distribuert skyvektordatabasen.",
    weaviate: "Åpen kildekode lokal og skyvert multimodal vektordatabasen.",
    milvus: "Åpen kildekode, svært skalerbar og utrolig rask.",
    astra: "Vektor-søk for virkelige GenAI.",
  },

  system: {
    title: "Systeminnstillinger",
    "desc-start":
      "Dette er de overordnede innstillingene og konfigurasjonene for din instans.",
    user: "Brukere kan fjerne arbeidsområder",
    "desc-delete":
      "Tillat ikke-administratorer å fjerne arbeidsområder som de er en del av. Dette vil fjerne arbeidsområdet for alle.",
    limit: "Begrens meldinger per bruker per dag",
    "desc-limit":
      "Begrens ikke-administratorer til et antall vellykkede forespørsel eller chatter innen et 24-timersvindu. Aktiver dette for å forhindre at brukere øker OpenAI-kostnadene.",
    "limit-day": "Meldingsgrense per dag",
  },

  "user-setting": {
    description:
      "Dette er alle kontoene som har en konto på denne instansen. Å fjerne en konto vil umiddelbart fjerne deres tilgang til denne instansen.",
    "add-user": "Legg til bruker",
    username: "Brukernavn",
    role: "Rolle",
    "date-added": "Dato lagt til",
  },

  support: {
    title: "Support E-post",
    description:
      "Angi supportens e-postadresse som vises i brukermenyen når du er logget inn på denne instansen.",
    clear: "Tøm",
    save: "Lagre",
  },
  "public-mode": {
    enable: "Aktiver Offentlig-Brukermodus",
    enabled: "Offentlig-Brukermodus er Aktivert",
  },
  button: {
    delete: "Slett",
    edit: "Rediger",
    suspend: "Suspender",
    unsuspend: "Gjenopprett",
  },

  "new-user": {
    title: "Legg til bruker i instansen",
    username: "Brukernavn",
    "username-ph": "Brukerens brukernavn",
    password: "Passord",
    "password-ph": "Brukerens initialpassord",
    role: "Rolle",
    default: "Standard",
    manager: "Leder",
    admin: "Administrator",
    description:
      "Etter å ha opprettet en bruker, må de logge inn med sitt initialpassord for å få tilgang.",
    cancel: "Avbryt",
    "add-User": "Legg til bruker",
    error: "Feil: ",
    permissions: {
      title: "Tillatelser",
      default: [
        "Kan kun sende chatter med arbeidsområder de har blitt lagt til av administrator eller ledere.",
        "Kan ikke endre noen innstillinger i det hele tatt.",
      ],
      manager: [
        "Kan vise, opprette og slette alle arbeidsområder og endre arbeidsområdespesifikke innstillinger.",
        "Kan opprette, oppdatere og invitere nye brukere til instansen.",
        "Kan ikke endre LLM, vectorDB, inbedding eller andre tilkoblinger.",
      ],
      admin: ["Høyeste brukerprivilegium.", "Kan se og gjøre alt i systemet."],
    },
  },

  "new-embed": {
    title: "Opprett ny inbedding for arbeidsområde",
    error: "Feil: ",
    "desc-start":
      "Etter å ha opprettet en inbedding vil du få en lenke som du kan publisere på nettstedet ditt med et enkelt",
    script: "script",
    tag: "tag.",
    cancel: "Avbryt",
    "create-embed": "Opprett inbedding",
    workspace: "Arbeidsområde",
    "desc-workspace":
      "Dette er arbeidsområdet chatvinduet ditt vil være basert på. Alle standardinnstillinger vil bli arvet fra arbeidsområdet med mindre de overstyres av denne konfigurasjonen.",
    "allowed-chat": "Tillatt chatmetode",
    "desc-query":
      "Angi hvordan chatboten din skal fungere. Spørsmål betyr at den bare svarer hvis et dokument hjelper til med å svare på spørsmålet.",
    "desc-chat":
      "Chat åpner chatten for generelle spørsmål og kan svare på spørsmål som ikke er relatert til arbeidsområdet ditt.",
    "desc-response": "Chat: Svar på alle spørsmål uavhengig av kontekst",
    "query-response":
      "Spørsmål: Svar kun på chatter relatert til dokumenter i arbeidsområdet",
    restrict: "Begrens forespørsel fra domener",
    filter:
      "Dette filteret blokkerer alle forespørsel som kommer fra en annen domene enn listen nedenfor.",
    "use-embed":
      "Å la dette stå tomt betyr at hvem som helst kan bruke inbeddingen din på hvilken som helst nettside.",
    "max-chats": "Maks chatter per dag",
    "limit-chats":
      "Begrens mengden chatter som denne inbeddede chatten kan behandle innen en 24-timersperiode. Null er ubegrenset.",
    "chats-session": "Maks chatter per sesjon",
    "limit-chats-session":
      "Begrens mengden chatter en sesjonsbruker kan sende med denne inbeddingen innen en 24-timersperiode. Null er ubegrenset.",
    "enable-dynamic": "Aktiver dynamisk modellanvendelse",
    "llm-override":
      "Tillat innstilling av den foretrukne LLM-modellen for å overstyre arbeidsområdets standard.",
    "llm-temp": "Aktiver dynamisk LLM-temperatur",
    "desc-temp":
      "Tillat innstilling av LLM-temperaturen for å overstyre arbeidsområdets standard.",
    "prompt-override": "Aktiver prompt-overstyring",
    "desc-override":
      "Tillat innstilling av systemprompten for å overstyre arbeidsområdets standard.",
  },

  "show-toast": {
    "recovery-codes": "Gjenopprettingskoder kopiert til utklipp",
    "scraping-website": "Skraper nettsted - dette kan ta litt tid.",
    "fetching-transcript": "Henter transkript for YouTube-video.",
    "updating-workspace": "Oppdaterer arbeidsområde...",
    "workspace-updated": "Arbeidsområde oppdatert vellykket.",
    "link-uploaded": "Lenke lastet opp vellykket",
    "password-reset": "Tilbakestilling av passord lyktes",
    "invalid-reset": "Ugyldig tilbakestillingstoken",
    "delete-option": "Tråden kunne ikke fjernes!",
    "thread-deleted": "Tråd fjernet vellykket!",
    "picture-uploaded": "Profilbilde lastet opp.",
    "profile-updated": "Profil oppdatert.",
    "logs-cleared": "Hendelseslogger tømt vellykket.",
    "preferences-updated": "Systeminnstillinger oppdatert vellykket.",
    "user-created": "Bruker opprettet vellykket.",
    "user-deleted": "Bruker fjernet fra systemet.",
    "workspaces-saved": "Arbeidsområder lagret vellykket!",
    "failed-workspaces": "Kunne ikke lagre arbeidsområder. Prøv igjen.",
    "api-deleted": "API-nøkkel slettet permanent",
    "api-copied": "API-nøkkel kopiert til utklipp",
    "appname-updated": "Tilpasset appnavn oppdatert vellykket.",
    "language-updated": "Språket har blitt oppdatert.",
    "palette-updated": "Oppdatert palett.",
    "image-uploaded": "Bilde lastet opp vellykket.",
    "image-removed": "Bilde fjernet vellykket.",
    "updated-welcome": "Velkomstmeldinger oppdatert vellykket.",
    "updated-footer": "Bunntekstikoner oppdatert vellykket.",
    "updated-paragraph": "Tilpasset paragraftekst oppdatert vellykket.",
    "updated-supportemail": "Support-e-post oppdatert vellykket.",
    "stt-success": "Tale-til-tekst-preferanser lagret vellykket.",
    "tts-success": "Tekst-til-tale-preferanser lagret vellykket.",
    "failed-chats-export": "Kunne ikke eksportere chatter.",
    "cleared-chats": "Alle chatter tømt.",
    "embed-deleted": "Inbedding fjernet fra systemet.",
    "snippet-copied": "Snippet kopiert til utklipp!",
    "embed-updated": "Inbedding oppdatert vellykket.",
    "embedding-saved": "Inbædningspreferanser lagret vellykket.",
    "chunking-settings": "Innstillinger for tekstdeling lagret.",
    "llm-saved": "LLM-preferanser lagret vellykket.",
    "multiuser-enabled": "Multi-Bruker-modus aktivert vellykket.",
    "publicuser-enabled": "Offentlig-Bruker-modus aktivert vellykket.",
    "publicuser-disabled": "Offentlig-Bruker-modus deaktivert vellykket.",
    "page-refresh": "Siden din vil bli oppdatert om noen sekunder.",
    "transcription-saved": "Transkripsjonspreferanser lagret vellykket.",
    "vector-saved": "Vektor-database-preferanser lagret vellykket.",
    "workspace-not-deleted": "Arbeidsområde kunne ikke slettes!",
    "maximum-messages": "Maksimalt 4 meldinger tillatt.",
    "users-updated": "Brukere oppdatert vellykket.",
    "vectordb-not-reset":
      "Arbeidsområdets vektordatabasen kunne ikke tilbakestilles!",
    "vectordb-reset": "Arbeidsområdets vektordatabasen tilbakestilt!",
    "linked-workspaces-updated": "Koblede arbeidsområder ble oppdatert.",
  },

  "llm-selection-privacy": {
    openai: {
      description: [
        "Dine chatter vil ikke bli brukt til trening",
        "Dine oppfordringer og dokumenttekst som brukes i svargenerering er synlige for OpenAI",
      ],
    },
    azure: {
      description: [
        "Dine chatter vil ikke bli brukt til trening",
        "Din tekst og inbeddingstekst er ikke synlige for OpenAI eller Microsoft",
      ],
    },
    anthropic: {
      description: [
        "Dine chatter vil ikke bli brukt til trening",
        "Dine oppfordringer og dokumenttekst som brukes i svargenerering er synlige for Anthropic",
      ],
    },
    gemini: {
      description: [
        "Dine chatter er avpersonifiserte og brukes i trening",
        "Dine oppfordringer og dokumenttekst som brukes i svargenerering er synlige for Google",
      ],
    },
    lmstudio: {
      description: [
        "Din modell og dine chatter er kun tilgjengelige på serveren som kjører LMStudio",
      ],
    },
    localai: {
      description: [
        "Din modell og dine chatter er kun tilgjengelige på serveren som kjører LocalAI",
      ],
    },
    ollama: {
      description: [
        "Din modell og dine chatter er kun tilgjengelige på maskinen som kjører Ollama-modeller",
      ],
    },
    native: {
      description: [
        "Din modell og dine chatter er kun tilgjengelige på denne plattformen",
      ],
    },
    togetherai: {
      description: [
        "Dine chatter vil ikke bli brukt til trening",
        "Dine oppfordringer og dokumenttekst som brukes i svargenerering er synlige for TogetherAI",
      ],
    },
    mistral: {
      description: [
        "Dine oppfordringer og dokumenttekst som brukes i svargenerering er synlige for Mistral",
      ],
    },
    huggingface: {
      description: [
        "Dine oppfordringer og dokumenttekst som brukes i svar sendes til din HuggingFace administrerte sluttpunkt",
      ],
    },
    perplexity: {
      description: [
        "Dine chatter vil ikke bli brukt til trening",
        "Dine oppfordringer og dokumenttekst som brukes i svargenerering er synlige for Perplexity AI",
      ],
    },
    openrouter: {
      description: [
        "Dine chatter vil ikke bli brukt til trening",
        "Dine oppfordringer og dokumenttekst som brukes i svargenerering er synlige for OpenRouter",
      ],
    },
    groq: {
      description: [
        "Dine chatter vil ikke bli brukt til trening",
        "Dine oppfordringer og dokumenttekst som brukes i svargenerering er synlige for Groq",
      ],
    },
    koboldcpp: {
      description: [
        "Din modell og dine chatter er kun tilgjengelige på serveren som kjører KoboldCPP",
      ],
    },
    textgenwebui: {
      description: [
        "Din modell og dine chatter er kun tilgjengelige på serveren som kjører Oobabooga Text Generation Web UI",
      ],
    },
    "generic-openai": {
      description: [
        "Data deles i henhold til brukervilkårene som gjelder for din generiske sluttpunktleverandør.",
      ],
    },
    cohere: {
      description: [
        "Data deles i henhold til brukervilkårene for cohere.com og dine lokale personvernlovgivninger.",
      ],
    },
    litellm: {
      description: [
        "Din modell og dine chatter er kun tilgjengelige på serveren som kjører LiteLLM",
      ],
    },
  },

  "vector-db-privacy": {
    chroma: {
      description: [
        "Dine vektorer og dokumenttekst lagres på din Chroma-instans",
        "Tilgang til din instans håndteres av deg",
      ],
    },
    pinecone: {
      description: [
        "Dine vektorer og dokumenttekst lagres på Pinecones servere",
        "Tilgang til dataene dine håndteres av Pinecone",
      ],
    },
    qdrant: {
      description: [
        "Dine vektorer og dokumenttekst lagres på din Qdrant-instans (sky eller egenvert)",
      ],
    },
    weaviate: {
      description: [
        "Dine vektorer og dokumenttekst lagres på din Weaviate-instans (sky eller egenvert)",
      ],
    },
    milvus: {
      description: [
        "Dine vektorer og dokumenttekst lagres på din Milvus-instans (sky eller egenvert)",
      ],
    },
    zilliz: {
      description: [
        "Dine vektorer og dokumenttekst lagres på din Zilliz skykluster.",
      ],
    },
    astra: {
      description: [
        "Dine vektorer og dokumenttekst lagres på din AstraDB skydatabase.",
      ],
    },
    lancedb: {
      description: [
        "Dine vektorer og dokumenttekst lagres privat på denne serveren",
      ],
    },
  },

  "embedding-engine-privacy": {
    native: {
      description: ["Din dokumenttekst integreres privat på denne serveren"],
    },
    openai: {
      description: [
        "Din dokumenttekst sendes til OpenAI-servere",
        "Dine dokument brukes ikke til trening",
      ],
    },
    azure: {
      description: [
        "Din dokumenttekst sendes til din Microsoft Azure-tjeneste",
        "Dine dokument brukes ikke til trening",
      ],
    },
    localai: {
      description: [
        "Din dokumenttekst integreres privat på serveren som kjører LocalAI",
      ],
    },
    ollama: {
      description: [
        "Din dokumenttekst integreres privat på serveren som kjører Ollama",
      ],
    },
    lmstudio: {
      description: [
        "Din dokumenttekst integreres privat på serveren som kjører LMStudio",
      ],
    },
    cohere: {
      description: [
        "Data deles i henhold til brukervilkårene for cohere.com og dine lokale personvernlovgivninger.",
      ],
    },
    voyageai: {
      description: [
        "Data som sendes til Voyage AIs servere deles i henhold til brukervilkårene for voyageai.com.",
      ],
    },
  },

  "prompt-validate": {
    edit: "Rediger",
    response: "Svar",
    prompt: "Oppfordring",
    regenerate: "Generer svaret på nytt",
    good: "Bra svar",
    bad: "Dårlig svar",
    copy: "Kopier",
    more: "Flere handlinger",
    fork: "Fork",
    delete: "Slett",
    cancel: "Avbryt",
    save: "Lagre & Send",
  },

  citations: {
    show: "Vis sitater",
    hide: "Skjul sitater",
    chunk: "Sitatdeler",
    pdr: "Foreldredokument hentet",
    "pdr-h": "Merk kildedokument",
    referenced: "Referert",
    times: "ganger.",
    citation: "Sitat",
    match: "match",
    download:
      "Denne nettleseren støtter ikke PDF-filer. Vennligst last ned PDF-filen for å vise den:",
    "download-btn": "Last ned PDF",
  },

  "document-drafting": {
    title: "Dokumentutkast",
    description: "Kontroller innstillingene dine for dokumentutkast.",
    configuration: "Konfigurasjon",
    "drafting-model": "Utkast LLM",
    enabled: "Dokumentutkast er aktivert",
    disabled: "Dokumentutkast er deaktivert",
    "enabled-toast": "Dokumentutkast aktivert",
    "disabled-toast": "Dokumentutkast deaktivert",
    "desc-settings":
      "Administrator kan endre innstillingene for dokumentutkast for alle brukere.",
    "drafting-llm": "Utkast LLM-preferanse",
    saving: "Lagrer...",
    save: "Lagre endringer",
  },

  modale: {
    document: {
      title: "Mine Dokumenter",
      document: "Dokument",
      search: "Søk etter dokument",
      folder: "Ny Mappe",
      name: "Navn",
      empty: "Ingen Dokumenter",
      "move-workspace": "Flytt til Arbeidsområde",
      "doc-processor": "Dokumentbehandler Utilgjengelig",
      "processor-offline":
        "Vi kan ikke laste opp filene dine akkurat nå fordi dokumentbehandleren er offline. Prøv igjen senere.",
      "drag-drop": "Klikk for å laste opp eller dra og slipp",
      "supported-files":
        "støtter mange filformater, men søkbar PDF er best for kildemerking m.m.",
      "submit-link": "eller send en lenke",
      fetch: "Hent nettsted",
      fetching: "Henter...",
      "file-desc":
        "Disse filene vil bli lastet opp til dokumentbehandleren som kjører på denne instansen. Disse filene blir ikke sendt eller delt med en tredjepart.",
      cost: "*Engangskostnad for inbædninger",
      "save-embed": "Lagre og Inbede",
      "loading-message": "Dette kan ta litt tid for store dokumenter",
    },
    connectors: {
      title: "Datakontakter",
      search: "Søk datakontakter",
      empty: "Ingen datakontakter funnet.",
    },
  },

  dataConnectors: {
    github: {
      name: "GitHub Repo",
      description:
        "Importer et helt offentlig eller privat GitHub-repo med ett klikk.",
      url: "GitHub Repo URL",
      "collect-url": "URL for GitHub-repoet du vil samle inn.",
      "access-token": "GitHub Tilgangstoken",
      optional: "valgfritt",
      "rate-limiting": "Tilgangstoken for å forhindre hastighetsbegrensning.",
      "desc-picker":
        "Når det er klart, vil alle filer være tilgjengelige for inbedding i arbeidsområdene i dokumentvelgeren.",
      branch: "Gren",
      "branch-desc": "Grenen du vil samle inn filer fra.",
      "branch-loading": "-- laster tilgjengelige grener --",
      "desc-start": "Uten å fylle ut",
      "desc-token": "GitHub Tilgangstoken",
      "desc-connector": "denne datakontakten vil kun kunne samle inn",
      "desc-level": "øverste nivå",
      "desc-end":
        "filer i repoet på grunn av GitHubs offentlige API-begrensninger.",
      "personal-token":
        "Få en gratis personlig tilgangstoken med en GitHub-konto her.",
      without: "Uten en",
      "personal-token-access": "Personlig Tilgangstoken",
      "desc-api":
        ", kan GitHub API begrense antallet filer som kan samles inn på grunn av hastighetsbegrensninger. Du kan",
      "temp-token": "opprette en midlertidig tilgangstoken",
      "avoid-issue": "for å unngå dette problemet.",
      submit: "Send",
      "collecting-files": "Samler inn filer...",
    },
    "youtube-transcript": {
      name: "YouTube Transkript",
      description:
        "Importer transkripsjonen av en hel YouTube-video fra en lenke.",
      url: "YouTube Video URL",
      "url-video": "URL for YouTube-videoen du vil transkribere.",
      collect: "Samle inn transkript",
      collecting: "Samler inn transkript...",
      "desc-end":
        "Når det er klart, vil transkripsjonen være tilgjengelig for inbedding i arbeidsområdene i dokumentvelgeren.",
    },
    "website-depth": {
      name: "Bulk Link Skraper",
      description:
        "Skrap en nettside og dens underlenker opp til en viss dybde.",
      url: "Nettside URL",
      "url-scrape": "URL for nettsiden du vil skrape.",
      depth: "Dybde",
      "child-links":
        "Dette er antallet underlenker som arbeideren skal følge fra den opprinnelige URL-en.",
      "max-links": "Maks Lenkene",
      "links-scrape": "Maksimalt antall lenker å skrape.",
      scraping: "Skraper nettside...",
      submit: "Send",
      "desc-scrap":
        "Når det er klart, vil alle skrapte sider være tilgjengelige for inbedding i arbeidsområdene i dokumentvelgeren.",
    },
    confluence: {
      name: "Confluence",
      description: "Importer en hel Confluence-side med ett klikk.",
      url: "Confluence Side URL",
      "url-page": "URL for en side i Confluence-rommet.",
      username: "Confluence Brukernavn",
      "own-username": "Ditt Confluence-brukernavn.",
      token: "Confluence Tilgangstoken",
      "desc-start":
        "Du må angi en tilgangstoken for autentisering. Du kan generere en tilgangstoken",
      here: "her",
      access: "Tilgangstoken for autentisering.",
      collecting: "Samler inn sider...",
      submit: "Send",
      "desc-end":
        "Når det er klart, vil alle sider være tilgjengelige for inbedding i arbeidsområdene.",
    },
  },

  module: {
    "legal-qa": "Juridisk undersøkelse",
    "document-drafting": "Dokumentutkast",
  },

  "fine-tune": {
    title: "Du har tilstrekkelig med data for en finjustering!",
    link: "klikk for å lære mer",
    dismiss: "avvis",
  },

  mobile: {
    disclaimer:
      "ADVARSEL: For best opplevelse og full tilgang til alle funksjoner, vennligst bruk en datamaskin for å få tilgang til appen.",
  },
  "confirm-message": {
    "delete-doc":
      "Er du sikker på at du vil slette disse filene og mappene?\nDette vil fjerne filene fra systemet og automatisk fjerne dem fra alle eksisterende arbeidsområder.\nDenne handlingen kan ikke reverseres.",
  },
};

export default TRANSLATIONS;
