import System from "@/models/system";
import { useState, useEffect } from "react";

export default function TogetherAiOptions({ settings, moduleSuffix = "" }) {
  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Together AI API Key
        </label>
        <input type="password" name={`TogetherAiApiKey${moduleSuffix}`} placeholder="Together AI API Key" defaultValue={settings?.[`TogetherAiApiKey${moduleSuffix}`] ? "*".repeat(20) : ""} required={true} autoComplete="off" spellCheck={false}
          className="modal-search-block normal-text  text-sm rounded-lg block w-full p-2.5"
        />
      </div>
      {!settings?.[`credentialsOnly${moduleSuffix}`] && (
        <TogetherAiModelSelection settings={settings} moduleSuffix={moduleSuffix} />
      )}
    </div>
  );
}
function TogetherAiModelSelection({ settings, moduleSuffix = "" }) {
  const [groupedModels, setGroupedModels] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      const { models } = await System.customModels("togetherai");

      if (models?.length > 0) {
        const modelsByOrganization = models.reduce((acc, model) => {
          acc[model.organization] = acc[model.organization] || [];
          acc[model.organization].push(model);
          return acc;
        }, {});

        setGroupedModels(modelsByOrganization);
      }

      setLoading(false);
    }
    findCustomModels();
  }, []);

  if (loading || Object.keys(groupedModels).length === 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Chat Model Selection
        </label>
        <select
          name={`TogetherAiModelPref${moduleSuffix}`}
          disabled={true}
          className="dark-input-mdl normal-text focus:outline-none text-sm rounded-lg block w-full p-2.5">
          <option disabled={true} selected={true} className="normal-text">
            -- loading available models --
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="normal-text text-sm font-semibold block mb-4">
        Chat Model Selection
      </label>
      <select name={`TogetherAiModelPref${moduleSuffix}`} required={true} className="dark-input-mdl normal-text focus:outline-none text-sm rounded-lg block w-full p-2.5">
        {Object.keys(groupedModels)
          .sort()
          .map((organization) => (
            <optgroup key={organization} label={organization}>
              {groupedModels[organization].map((model) => (
                <option className="normal-text"
                  key={model.id}
                  value={model.id}
                  selected={settings?.[`TogetherAiModelPref${moduleSuffix}`] === model.id}
                >
                  {model.name}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
    </div>
  );
}
