import { useEffect, useState } from "react";
import { Info } from "@phosphor-icons/react";
import paths from "@/utils/paths";
import System from "@/models/system";

export default function LocalAiOptions({ settings, showAlert = false, moduleSuffix = "" }) {
  const [basePathValue, setBasePathValue] = useState(settings?.[`LocalAiBasePath${moduleSuffix}`]);
  const [basePath, setBasePath] = useState(settings?.[`LocalAiBasePath${moduleSuffix}`]);
  const [apiKeyValue, setApiKeyValue] = useState(settings?.[`LocalAiApiKey${moduleSuffix}`]);
  const [apiKey, setApiKey] = useState(settings?.[`LocalAiApiKey${moduleSuffix}`]);

  return (
    <div className="w-full flex flex-col gap-y-4">
      {showAlert && (
        <div className="flex flex-col md:flex-row md:items-center gap-x-2 normal-text mb-6 bg-blue-800/30 w-fit rounded-lg px-4 py-2">
          <div className="gap-x-2 flex items-center">
            <Info size={12} className="hidden md:visible" />
            <p className="text-sm md:text-base">
              LocalAI as your LLM requires you to set an embedding service to
              use.
            </p>
          </div>
          <a
            href={paths.settings.embedder.modelPreference()}
            className="text-sm md:text-base my-2 underline"
          >
            Manage embedding &rarr;
          </a>
        </div>
      )}
      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            Local AI Base URL
          </label>
          <input
            type="url"
            name={`LocalAiBasePath${moduleSuffix}`}
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5"
            placeholder="http://localhost:1234/v1"
            defaultValue={settings?.[`LocalAiBasePath${moduleSuffix}`] || "http://localhost:1234/v1"}
            required={true}
            autoComplete="off"
            spellCheck={false}
            onChange={(e) => setBasePathValue(e.target.value)}
            onBlur={() => setBasePath(basePathValue)}
          />
        </div>
        {!settings?.[`credentialsOnly${moduleSuffix}`] && (
          <>
            <LocalAIModelSelection
              settings={settings}
              basePath={basePath}
              apiKey={apiKey}
              moduleSuffix={moduleSuffix}
            />
            <div className="flex flex-col w-60">
              <label className="normal-text text-sm font-semibold block mb-4">
                Token context window
              </label>
              <input
                type="number"
                name={`LocalAiTokenLimit${moduleSuffix}`}
                className=" dark-input-mdl normal-text text-sm rounded-lg   block w-full p-2.5"
                placeholder="4096"
                min={1}
                onScroll={(e) => e.target.blur()}
                defaultValue={settings?.[`LocalAiTokenLimit${moduleSuffix}`]}
                required={true}
                autoComplete="off"
              />
            </div>
          </>
        )}
      </div>
      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-60">
          <div className="flex flex-col gap-y-1 mb-4">
            <label className="normal-text text-sm font-semibold flex items-center gap-x-2">
              Local AI API Key{" "}
              <p className="!text-xs !italic !font-thin">optional</p>
            </label>
          </div>

          <input
            type="password"
            name={`LocalAiApiKey${moduleSuffix}`}
            className=" dark-input-mdl normal-text text-sm rounded-lg block w-full p-2.5"
            placeholder="sk-mysecretkey"
            defaultValue={settings?.[`LocalAiApiKey${moduleSuffix}`] ? "*".repeat(20) : ""}
            autoComplete="off"
            spellCheck={false}
            onChange={(e) => setApiKeyValue(e.target.value)}
            onBlur={() => setApiKey(apiKeyValue)}
          />
        </div>
      </div>
    </div>
  );
}

function LocalAIModelSelection({ settings, basePath = null, apiKey = null, moduleSuffix = "" }) {
  const [customModels, setCustomModels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      if (!basePath || !basePath.includes("/v1")) {
        setCustomModels([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      const { models } = await System.customModels(
        "localai",
        typeof apiKey === "boolean" ? null : apiKey,
        basePath
      );
      setCustomModels(models || []);
      setLoading(false);
    }
    findCustomModels();
  }, [basePath, apiKey]);

  if (loading || customModels.length == 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Chat Model Selection
        </label>
        <select
          name={`LocalAiModelPref${moduleSuffix}`}
          disabled={true}
          className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-2.5"
        >
          <option disabled={true} selected={true} className="normal-text">
            {basePath?.includes("/v1")
              ? "-- loading available models --"
              : "-- waiting for URL --"}
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="normal-text text-sm font-semibold block mb-4">
        Chat Model Selection
      </label>
      <select
        name={`LocalAiModelPref${moduleSuffix}`}
        required={true}
        className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-2.5"
      >
        {customModels.length > 0 && (
          <optgroup label="Your loaded models">
            {customModels.map((model) => {
              return (
                <option className="normal-text"
                  key={model.id}
                  value={model.id}
                  selected={settings?.[`LocalAiModelPref${moduleSuffix}`] === model.id}
                >
                  {model.id}
                </option>
              );
            })}
          </optgroup>
        )}
      </select>
    </div>
  );
}
